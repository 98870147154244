import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }
const _hoisted_2 = {
  href: "#",
  class: "mb-12"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getIllustrationsPath('14.png') + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", _hoisted_2, [
        _createElementVNode("img", {
          alt: "Logo",
          src: _ctx.themeLightLogoIconText,
          class: "h-45px"
        }, null, 8, _hoisted_3)
      ]),
      _createVNode(_component_router_view)
    ])
  ], 4))
}